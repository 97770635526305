import { createI18n } from 'vue-i18n'

// 从语言包文件中导入语言包对象
import zh from './zh.js'
import en from './en.js'

import de from './de.js'
import fr from './fr.js'
import es from './es.js'
import ita from './ita.js'
import pt from './pt.js'
import nl from './nl.js'
import el from './el.js'
import pl from './pl.js'
//使用vuex
// import store from '../store',

const messages = {
    en,de,fr,es,ita,pt,nl,el,pl
}
// console.log(store.state.langs)

const i18n =createI18n( {
    legacy: false,         // 使用 Composition API 模式，则需要将其设置为false
    globalInjection: true, 
    locale: localStorage.getItem('lang')||'en',   
    fallbackLocale: 'en',      
    messages             
  })
   
  export default i18n;