export default {
    navList: {
        nav1: 'Início',
        nav2: 'Vantagens',
        nav3: 'Portfólio',
        nav4: 'Vídeos AI',
        nav5: 'Contato',
        login: 'Entrar',
        register: 'Registrar'
    },
    Language: "Idioma",
    down: 'Baixar',
    Menu: 'Menu',
    bannerTitle: '[STAR AI] Trading Quantitativo',
    bannerSubtitle: 'Rendimento Estável',
    bannerListTxt1: 'Rebalanceamento',
    bannerListTxt2: 'Gestão de Portfólio',
    bannerListTxt3: 'Coleta de Dados',
    bannerListTxt4: 'Roteamento Inteligente',
    bannerDownBtn: 'Baixar para Android',
    bannerSubscribeBtn: 'Assine Agora',
    ProductAdvantages: 'Vantagens vs Humano',
    advantagesList: {
        title1: 'A diferença entre vantagens do produto e mão de obra',
        describe1: 'As vantagens do design dos modelos de estratégia quantitativa de IA Cada modelo quantitativo de IA é composto por mais de centenas de fatores que podem afetar a ascensão e queda das ações. Possui capacidades independentes de aprendizagem profunda e pode acompanhar o fluxo principal de fundos. É difícil que a análise e a pesquisa manuais atendam a tais requisitos.',
        title2: 'Vantagens da função de teste de retorno de dados históricos',
        describe2: 'Para projetar um modelo quantitativo de IA com uma taxa de retorno que excede em muito a taxa de retorno do mercado, você pode obter a taxa de retorno histórica anualizada do modelo testando os dados históricos do ciclo. O modelo mais ideal pode ser selecionado entre eles. Isso é simplesmente impossível de fazer manualmente, não importa quanto tempo leve. Portanto, as operações manuais muitas vezes não são rigorosas e não científicas, e dependem apenas de sentimentos e experiências pessoais para selecionar alvos para operação.',
        title3: 'Vantagens da digitalização automática de mais de 400.000 ações no mercado global',
        describe3: 'O robô AI verifica automaticamente mais de 400.000 ações em todo o mercado ao longo do dia, de acordo com as condições e outros parâmetros definidos pelos técnicos do modelo quantitativo, e conclui a operação imediatamente quando descobre que as condições-alvo definidas são atendidas. Leva apenas alguns segundos. A análise manual levaria anos ou até décadas e não seria capaz de concluir a mesma quantidade de trabalho.',
        title4: 'Somente compreendendo a história poderemos julgar o futuro com mais precisão',
        describe4: 'A taxa de retorno do backtest de dados históricos do modelo quantitativo pode ser usada como uma melhor referência para julgar as taxas de retorno futuras. Os engenheiros técnicos só precisam inserir parâmetros diretamente na direção das ações compradas naquele dia e podem testar a taxa de retorno diária. do estoque no ponto de compra. Para atender a esses requisitos, a operação manual é simplesmente impossível.',
        title5: 'Estabeleça um modelo de lucro estável e de longo prazo',
        describe5: 'Para obter lucros estáveis ​​a longo prazo e enormes juros compostos, os engenheiros técnicos podem personalizar e adicionar seus próprios parâmetros de indicadores selecionados, verificar a taxa de retorno por meio de backtesting, selecionar a estratégia ou combinação mais ideal e, em seguida, conduzir negociações totalmente automáticas com robôs de IA. Este tipo de trabalho não pode ser feito manualmente.',
        title6: 'Capacidade de execução operacional',
        describe6: 'A vantagem do comércio quantitativo totalmente automático da STAR AI é que utiliza ferramentas racionais para operar transacções de acordo com as configurações do modelo. O robô não tem alterações emocionais e pode superar a natureza humana, a ganância e o medo humanos e muitas outras deficiências e fraquezas no processo de operação. As operações manuais são frequentemente afectadas pela ascensão e queda do mercado e por vários outros factores, e não podem ser operadas de acordo com o melhor modelo de lucro durante muito tempo.',
        title7: 'Liberar completamente a produtividade',
        describe7: 'O robô quantitativo STAR AI pode ser configurado uma vez e realizar operações automaticamente de acordo com as configurações por um longo período. Ele só precisa realizar o processo de alocação de capital uma vez por dia, o que pode liberar completamente os usuários do árduo trabalho de negociação de ações. No entanto, a análise e pesquisa manual, o acompanhamento do mercado e a negociação manual confiada exigem muito tempo e energia dos investidores.',
    },
    investmentPackage: "Escolha seu Portfólio",
    versionTab1: 'Básico',
    versionTab2: 'Iniciante',
    versionTab3: 'Padrão',
    versionTab4: 'Avançado',
    versionTab5: 'Pro',
    versionTab6: 'Empresarial',
    versionTab7: 'Personalizado',
    BusinessDocking: 'Contato Comercial',
    email: "E-mail",
    officialEmail: 'E-mail Oficial',
    privacyPolicy: 'Política de Privacidade',
    TermOfService: "Termos de Serviço",
    Copyright: 'Direitos Autorais'
}