

const packListData = {
    'zh': {
        listdata: [
            [
                {
                    title: "基础版AI",
                    illustrate1: '要求配资金额：100美元-300美元',
                    illustrate2: '日利率：0.44%-0.45%',
                    illustrate3: '月利率:约13.2%-13.5%',
                    illustrate4: '签约：15日',
                    introduce: '【基础版AI】开放是为了新手第一次使用给与体验，可以体验公司的产品带来的回报率效果和稳定性签约成功后需要每日进行配资才能获得收益投资100USDT，15日回报收益约为7USDT或更多投资300USDT，15日回报收益约为21USDT或更多投资金额可在100至300数字任意之间选择'
                }
            ],
            [
                {
                    title: "入门版AI",
                    illustrate1: '要求配资金额：500USDT-1000USDT',
                    illustrate2: '日利率：0.48%-0.50%',
                    illustrate3: '月利率:14.4%-15%',
                    illustrate4: '签约：30日 ',
                    introduce: '签约成功后需要每日进行配资才能获得收益投资500USDT，30日回报收益大约72USDT或更多投资1000USDT,30日回报收益大约144USDT或更多投资金额可在500至1000数字之间任意选择'
                }
            ],
            [
                {
                    title: "标准版AI",
                    illustrate1: '要求配资金额：1000USDT-3000USDT',
                    illustrate2: '日利率：0.60%-0.65%',
                    illustrate3: '月利率:18%-19.5%',
                    illustrate4: '签约：30日 ',
                    introduce: '签约成功后需要每日进行配资才能获得收益投资1000USDT，30日回报收益大约180USDT或更多投资3000USDT,30日回报收益大约540USDT或更多投资金额可在1000至3000数字之间任意选择,30日回报收益大约144USDT或更多投资金额可在500至1000数字之间任意选择'
                }
            ],
            [
                {
                    title: "高级版AI",
                    illustrate1: '要求配资金额：3000USDT-10000USDT',
                    illustrate2: '日利率：0.70%-0.75%',
                    illustrate3: '月利率:21%-22.5%',
                    illustrate4: '签约：30日',
                    introduce: '要求配资金额：3000USDT-10000USDT日利率：0.70%-0.75%月利率:21%-22.5%合约：30日 签约成功后需要每日进行配资才能获得收益投资3000USDT，30日回报收益大约630USDT或更多投资10000USDT，30日获得回报收益大约2100USDT或更多投资金额可在3000至10000数字之间任意选择'
                }


            ],
            [{
                title: "专业版AI",
                illustrate1: '要求配资金额：10000USDT-30000USDT',
                illustrate2: '日利率：0.80%-0.85%',
                illustrate3: '月利率:24%-25.5%',
                illustrate4: '签约：30日',
                introduce: '签约成功后需要每日进行配资才能获得收益投资10000USDT，30日回报收益大约2400USDT或更多投资30000USDT，30日获得回报收益大约7200USDT或更多投资金额可在10000至30000数字之间任意选择'
            }
            ],
            [{
                title: "企业版AI",
                illustrate1: '要求配资金额：30000USDT-50000USDT',
                illustrate2: '日利率：0.90%-0.95%',
                illustrate3: '月利率:27%-28.5%',
                illustrate4: '签约：30日',
                introduce: '投资30000USDT，30日回报收益大约8100USDT或更多投资50000USDT，30日获得回报收益大约13500USDT或更多投资金额可在30000至50000数字之间任意选择'
            }],
            [
                {
                    title: "定制版AI",
                    illustrate1: '要求配资金额：50000-200000',
                    illustrate2: '日利率：0.01-0.012',
                    illustrate3: '月利率:30%-36%',
                    illustrate4: '签约：30日',
                    introduce: ' 签约成功后需要每日进行配资才能获得收益投资50000USDT，30日回报收益大约15000USDT或更多投资200000USDT，30日获得回报收益大约60000USDT或更多投资金额可在50000至200000数字之间任意选择'
                }
            ]
        ]
    },
    'en': {
        listdata: [
            [
                {
                    title: "Basic AI",
                    illustrate1: 'Required allocation amount: 100USDT-300USDT',
                    illustrate2: 'Daily interest rate: 0.44%-0.45%',
                    illustrate3: 'Monthly interest rate: about 13.2%-13.5%',
                    illustrate4: 'Signing: 15th',
                    introduce: "[Basic version of AI] is open for novices to experience when using it for the first time. They can experience the rate of return effect and stability brought by the company's products. After successful signing, you need to allocate funds every day to get income. Invest 100USDT, and the income will be returned on the 15th. About 7USDT or more investment of 300USDT, 15-day return income is about 21USDT or more. The investment amount can be chosen between 100 and 300 numbers."
                }
            ],
            [
                {
                    title: "Entry Edition AI",
                    illustrate1: 'Required allocation amount: 500USDT-1000USDT',
                    illustrate2: 'Daily interest rate: 0.48%-0.50%',
                    illustrate3: 'Monthly interest rate: 14.4%-15%',
                    illustrate4: 'Signing time: 30 days ',
                    introduce: 'After successfully signing the contract, allocate funds every day to get income. The investment requires 500USDT. The return income on 30 days is about 72USDT or more. Invest 1,000USDT. The return income on 30th day is about 144USDT or more. The investment amount can be chosen between 500 and 1,000.'
                }
            ],
            [
                {
                    title: "Standard version AI",
                    illustrate1: 'Required allocation amount: 1000USDT-3000USDT',
                    illustrate2: 'Daily interest rate: 0.60%-0.65%',
                    illustrate3: 'Monthly interest rate: 18%-19.5%',
                    illustrate4: 'Signing: 30 days ',
                    introduce: 'After successfully signing the contract, you need to allocate funds every day to get the income. Invest 1,000USDT. The return income on the 30th day is about 180USDT or more. Invest 3,000USDT. The return income on the 30th day is about 540USDT or more. The investment amount can be chosen between 1,000 and 3,000 numbers. 30 The daily return income is about 144USDT or more. The investment amount can be chosen between 500 and 1000 numbers.'
                }
            ],
            [
                {
                    title: "Advanced AI",
                    illustrate1: 'Required allocation amount: 3000USDT-10000USDT',
                    illustrate2: 'Daily interest rate: 0.70%-0.75%',
                    illustrate3: 'Monthly interest rate: 21%-22.5%',
                    illustrate4: 'Signing: 30 days',
                    introduce: 'Required allocation amount: 3000USDT-10000USDT Daily interest rate: 0.70%-0.75% Monthly interest rate: 21%-22.5% Contract: After successful signing on the 30th, daily allocation is required to obtain income. Invest 3000USDT, and the return income on the 30th will be approximately 630USDT or If you invest more than 10,000USDT, you will get returns in 30 days. The investment amount is about 2,100USDT or more. You can choose any number between 3,000 and 10,000.'
                }


            ],
            [{
                title: "Professional version AI",
                illustrate1: 'Required allocation amount: 10,000USDT-30,000USDT',
                illustrate2: 'Daily interest rate: 0.80%-0.85%',
                illustrate3: 'Monthly interest rate: 24%-25.5%',
                illustrate4: 'Signing: 30 days',
                introduce: 'After successfully signing the contract, you need to allocate funds every day to get the income. Invest 10,000USDT. The return on the 30th will be about 2,400USDT or more. Invest 30,000USDT, and the return on the 30th will be about 7,200USDT or more. The investment amount can be chosen between 10,000 and 30,000.'
            }
            ],
            [{
                title: "Enterprise Edition AI",
                illustrate1: 'Required allocation amount: 30,000USDT-50,000USDT',
                illustrate2: 'Daily interest rate: 0.90%-0.95%',
                illustrate3: 'Monthly interest rate: 27%-28.5%',
                illustrate4: 'Signing: 30 days',
                introduce: 'Invest 30,000USDT and get a return of about 8,100USDT or more in 30 days. Invest 50,000USDT and get a return of about 13,500USDT or more in 30 days. The investment amount can be chosen between 30,000 and 50,000.'
            }],
            [
                {
                    title: "Customized version of AI",
                    illustrate1: 'Required allocation amount: 50,000USDT-200,000USDT',
                    illustrate2: 'Daily interest rate: 0.01-0.012',
                    illustrate3: 'Monthly interest rate: 30%-36%',
                    illustrate4: 'Signing: 30 days',
                    introduce: 'After successfully signing the contract, you need to allocate funds every day to get the income. Invest 50,000USDT. The return on the 30th will be about 15,000USDT or more. Invest 200,000USDT. The return on the 30th will be about 60,000USDT or more. The investment amount can be chosen between 50,000 and 200,000.'
                }
            ]
        ]
    },
    'de': {
        listdata: [
            [
                {
                    title: "Grundlegende AI",
                    illustrate1: 'Erforderlicher Zuteilungsbetrag: 100–300 US-Dollar',
                    illustrate2: 'Täglicher Zinssatz: 0,44 %–0,45 %',
                    illustrate3: 'Monatlicher Zinssatz: ca. 13,2 %-13,5 %',
                    illustrate4: 'Unterzeichnung: 15',
                    introduce: '[Die Basisversion von AI] steht Anfängern offen, wenn sie sie zum ersten Mal verwenden. Sie können die Rendite und Stabilität der Produkte des Unternehmens erleben. Nach erfolgreicher Unterzeichnung müssen Sie jeden Tag Mittel bereitstellen, um Einnahmen zu erzielen . Investieren Sie 100 USDT und das Einkommen wird am 15. zurückerstattet. Bei einer Investition von 300 USDT beträgt die Rendite nach 15 Tagen etwa 21 USDT oder mehr. Der Investitionsbetrag kann zwischen 100 und 300 betragen.'
                }
            ],
            [
                {
                    title: "Entry Edition AI",
                    illustrate1: 'Erforderlicher Zuteilungsbetrag: 500 USDT–1.000 USDT',
                    illustrate2: 'Täglicher Zinssatz: 0,48 % – 0,50 %',
                    illustrate3: 'Monatlicher Zinssatz: 14,4 %–15 %',
                    illustrate4: 'Unterzeichnung: 30 Tage',
                    introduce: 'Nach erfolgreicher Unterzeichnung des Vertrags müssen Sie jeden Tag Mittel bereitstellen, um 500 USDT zu verdienen. Die Rendite beträgt nach 30 Tagen mindestens 144 USDT Der Betrag kann zwischen 500 und 1000 gewählt werden.'
                }
            ],
            [
                {
                    title: "Standardversion AI",
                    illustrate1: 'Erforderlicher Zuteilungsbetrag: 1000 USDT-3000 USDT',
                    illustrate2: 'Täglicher Zinssatz: 0,60 %-0,65 %',
                    illustrate3: 'Monatlicher Zinssatz: 18 %–19,5 %',
                    illustrate4: 'Unterzeichnung: 30 Tage',
                    introduce: 'Nach erfolgreicher Vertragsunterzeichnung müssen Sie jeden Tag Mittel bereitstellen, um das Einkommen von 1.000 USDT oder mehr zu erzielen mehr. Der Anlagebetrag kann zwischen 1.000 und 3.000 Zahlen gewählt werden. Die tägliche Rendite beträgt ca. 144 USDT oder mehr.'
                }
            ],
            [
                {
                    title: "Fortschrittliche AI",
                    illustrate1: 'Erforderlicher Zuteilungsbetrag: 3.000 USDT–10.000 USDT',
                    illustrate2: 'Täglicher Zinssatz: 0,70 %–0,75 %',
                    illustrate3: 'Monatlicher Zinssatz: 21 %–22,5 %',
                    illustrate4: 'Unterzeichnung: 30 Tage',
                    introduce: 'Erforderlicher Zuteilungsbetrag: 3.000 USDT – 10.000 USDT. Täglicher Zinssatz: 0,70 % – 0,75 %. Monatlicher Zinssatz: 21 % – 22,5 %. Vertrag: Nach erfolgreicher Unterzeichnung am 30. ist eine tägliche Zuteilung erforderlich, um 3.000 USDT zu investieren und die Rendite zu erzielen der 30. beträgt etwa 630 USDT oder wenn Sie mehr als 10.000 USDT investieren, erhalten Sie innerhalb von 30 Tagen eine Rendite. Der Investitionsbetrag beträgt etwa 2.100 USDT oder mehr. Sie können eine beliebige Zahl zwischen 3.000 und 10.000 wählen.'
                }


            ],
            [{
                title: "Professionelle Version der AI",
                illustrate1: 'Erforderlicher Zuteilungsbetrag: 10.000 USDT–30.000 USDT',
                illustrate2: 'Täglicher Zinssatz: 0,80 %-0,85 %',
                illustrate3: 'Monatlicher Zinssatz: 24 %–25,5 %',
                illustrate4: 'Unterzeichnung: 30 Tage',
                introduce: 'Nach erfolgreicher Unterzeichnung des Vertrags müssen Sie jeden Tag Mittel bereitstellen, um das Einkommen von 10.000 USDT zu erzielen. Die Rendite beträgt am 30. USDT oder mehr, und die Rendite am 30. USDT beträgt etwa 7.200 USDT Der Anlagebetrag kann zwischen 10.000 und 30.000 gewählt werden.'
            }
            ],
            [{
                title: "Enterprise Edition AI",
                illustrate1: 'Erforderlicher Zuteilungsbetrag: 30.000 USDT–50.000 USDT',
                illustrate2: 'Täglicher Zinssatz: 0,90 %–0,95 %',
                illustrate3: 'Monatlicher Zinssatz: 27 %–28,5 %',
                illustrate4: 'Unterzeichnung: 30 Tage',
                introduce: 'Investieren Sie 30.000 USDT und erzielen Sie in 30 Tagen eine Rendite von ca. 8.100 USDT oder mehr. Investieren Sie 50.000 USDT und erzielen Sie in 30 Tagen eine Rendite von ca. 13.500 USDT.'
            }],
            [
                {
                    title: "Angepasste Version von AI",
                    illustrate1: 'Erforderlicher Zuteilungsbetrag: 50.000-200.000',
                    illustrate2: 'Tageszinssatz: 0,01-0,012',
                    illustrate3: 'Monatlicher Zinssatz: 30 %–36 %',
                    illustrate4: 'Unterzeichnung: 30 Tage',
                    introduce: 'Nach erfolgreicher Unterzeichnung des Vertrags müssen Sie jeden Tag Mittel bereitstellen, um 50.000 USDT zu erhalten. Die Rendite beträgt am 30. USDT oder mehr Der Anlagebetrag kann zwischen 50.000 und 200.000 gewählt werden.'
                }
            ]
        ]
    },
    'fr': {
        listdata: [
            [
                {
                    title: "Version de base AI",
                    illustrate1: "Montant de l'allocation requis : 100 - 300 USDT",
                    illustrate2: "Taux d'intérêt journalier : 0,44 %-0,45 %",
                    illustrate3: "Taux d'intérêt mensuel : environ 13,2%-13,5%",
                    illustrate4: "Signature : 15",
                    introduce: "[La version de base de l'IA] est ouverte aux novices lorsqu'ils l'utilisent pour la première fois. Ils peuvent découvrir le taux de rendement et la stabilité apportés par les produits de l'entreprise. Après une signature réussie, vous devez allouer des fonds chaque jour pour obtenir des revenus. . Investissez 100 USDT et le revenu sera restitué le 15. Environ 7 USDT ou plus d'investissement de 300 USDT, le revenu de retour sur 15 jours est d'environ 21 USDT ou plus. Le montant de l'investissement peut être choisi entre 100 et 300 chiffres."
                }
            ],
            [
                {
                    title: "Commencer AI",
                    illustrate1: "Montant de l'allocation requis : 500 USDT à 1 000 USDT",
                    illustrate2: "Taux d'intérêt journalier : 0,48 %-0,50 %",
                    illustrate3: "Taux d'intérêt mensuel : 14,4 %-15 %",
                    illustrate4: "Signature : 30 jours",
                    introduce: "Après avoir signé le contrat avec succès, vous devez allouer des fonds chaque jour pour obtenir le revenu. Investissez 500 USDT. Le retour sur 30 jours sera d'environ 72 USDT ou plus. Investissez 1 000 USDT. Le retour sur 30 jours sera d'environ 144 USDT ou plus. le montant peut être choisi entre 500 et 1000."
                }
            ],
            [
                {
                    title: "Version standard AI",
                    illustrate1: "Montant de l'allocation requis : 1 000 USDT à 3 000 USDT",
                    illustrate2: "Taux d'intérêt journalier : 0,60 %-0,65 %",
                    illustrate3: "Taux d'intérêt mensuel : 18%-19,5%",
                    illustrate4: "Signature : 30 jours",
                    introduce: "Après avoir signé le contrat avec succès, vous devez allouer des fonds chaque jour pour obtenir le revenu. Investissez 1 000 USDT. Le revenu de retour au 30ème jour est d'environ 180 USDT ou plus. Investissez 3 000 USDT ou plus. plus. Le montant de l'investissement peut être choisi entre 1 000 et 3 000 numéros. 30 Le revenu de rendement quotidien est d'environ 144 USDT ou plus. Le montant de l'investissement peut être choisi entre 500 et 1 000 numéros."
                }
            ],
            [
                {
                    title: "Version premium AI",
                    illustrate1: "Montant de l'allocation requis : 3 000 USDT à 10 000 USDT",
                    illustrate2: "Taux d'intérêt journalier : 0,70 %-0,75 %",
                    illustrate3: "Taux d'intérêt mensuel : 21 %-22,5 %",
                    illustrate4: "Signature : 30 jours",
                    introduce: "Montant d'allocation requis : 3 000 USDT - 10 000 USDT Taux d'intérêt quotidien : 0,70 % - 0,75 % Taux d'intérêt mensuel : 21 % - 22,5 % Contrat : Après une signature réussie le 30, une allocation quotidienne est requise pour obtenir un revenu de 3 000 USDT et le retour des revenus. le 30 sera d'environ 630 USDT ou si vous investissez plus de 10 000 USDT, vous obtiendrez des retours dans 30 jours. Le montant de l'investissement est d'environ 2 100 USDT ou plus. Vous pouvez choisir n'importe quel nombre entre 3 000 et 10 000."
                }


            ],
            [{
                title: "Version professionnelle AI",
                illustrate1: "Montant de l'allocation requis: 10000 USDT à 30 000 USDT",
                illustrate2: "Taux d'intérêt journalier: 0,80%-0,85 %",
                illustrate3: "Taux d'intérêt mensuel : 24 %-25,5 %",
                illustrate4: "Signature : 30 jours",
                introduce: "Après avoir signé le contrat avec succès, vous devez allouer des fonds chaque jour pour obtenir un revenu. Investissez 10 000 USDT. Le rendement du 30 sera d'environ 2 400 USDT ou plus. Investissez 30 000 USDT, et le rendement du 30 sera d'environ 7 200 USDT. ou plus. Le montant de l'investissement peut être choisi entre 10 000 et 30 000."
            }
            ],
            [{
                title: "Édition Entreprise AI",
                illustrate1: "Montant de l'allocation requis : 30 000 USDT à 50 000 USDT",
                illustrate2: "Taux d'intérêt journalier : 0,90 %-0,95 %",
                illustrate3: "Taux d'intérêt mensuel : 27%-28,5%",
                illustrate4: "Signature : 30 jours",
                introduce: "Investissez 30 000 USDT et obtenez un rendement d'environ 8 100 USDT ou plus en 30 jours. Investissez 50 000 USDT et obtenez un rendement d'environ 13 500 USDT ou plus en 30 jours. Le montant de l'investissement peut être choisi en"
            }],
            [
                {
                    title: "Version personnalisée AI",
                    illustrate1: "Montant de l'allocation requis : 50 000 à 200 000",
                    illustrate2: "Taux d'intérêt quotidien : 0,01-0,012",
                    illustrate3: "Taux d'intérêt mensuel : 30 % à 36 %",
                    illustrate4: "Signature : 30 jours",
                    introduce: "Après avoir signé le contrat avec succès, vous devez allouer des fonds chaque jour pour obtenir le revenu. Investissez 50 000 USDT. Le rendement du 30 sera d'environ 15 000 USDT ou plus. Investissez 200 000 USDT ou plus. plus Le montant de l'investissement peut être choisi entre 50 000 et 200 000."
                }
            ]
        ]
    },
    'es': {
        listdata: [
            [
                {
                    title: "Versión básica AI",
                    illustrate1: 'Monto de asignación requerido: US$100-US$300',
                    illustrate2: 'Tasa de interés diaria: 0,44%-0,45%',
                    illustrate3: 'Tasa de interés mensual: alrededor del 13,2% -13,5%',
                    illustrate4: 'Firma: 15',
                    introduce: '[La versión básica de AI] está abierta para que la experimenten los principiantes cuando la usan por primera vez. Pueden experimentar el efecto de tasa de retorno y la estabilidad que brindan los productos de la compañía. Después de firmar con éxito, deben asignar fondos todos los días para obtener ingresos. Invierta 100 USDT y los ingresos se devolverán el día 15. Una inversión de aproximadamente 7 USDT o más de 300 USDT, el ingreso de retorno de 15 días es de aproximadamente 21 USDT o más. El monto de la inversión se puede elegir entre 100 y 300 números.'
                }
            ],
            [
                {
                    title: "Edición inicial AI",
                    illustrate1: 'Monto de asignación requerido: 500USDT-1000USDT',
                    illustrate2: 'Tasa de interés diaria: 0,48%-0,50%',
                    illustrate3: 'Tasa de interés mensual: 14,4%-15%',
                    illustrate4: 'Firma: 30 días',
                    introduce: 'Después de firmar con éxito el contrato, debe asignar fondos todos los días para obtener ingresos. Invierta 500 USDT. El rendimiento en 30 días será de aproximadamente 72 USDT o más. Invierta 1000 USDT. El rendimiento en 30 días será de aproximadamente 144 USDT o más. La cantidad se puede elegir entre 500 y 1000.'
                }
            ],
            [
                {
                    title: "Edición estándar AI",
                    illustrate1: 'Monto de asignación requerido: 1000USDT-3000USDT',
                    illustrate2: 'Tasa de interés diaria: 0,60%-0,65%',
                    illustrate3: 'Tasa de interés mensual: 18%-19,5%',
                    illustrate4: 'Firma: 30 días',
                    introduce: 'Después de firmar con éxito el contrato, debe asignar fondos todos los días para obtener el ingreso. Invierta 1000 USDT. El ingreso de retorno el día 30 es de aproximadamente 180 USDT o más. más El monto de la inversión se puede elegir entre 1000 y 3000 números. 30 El ingreso de retorno diario es de aproximadamente 144 USDT o más. El monto de la inversión se puede elegir entre 500 y 1000 números.'
                }
            ],
            [
                {
                    title: "Versión prémium AI",
                    illustrate1: 'Monto de asignación requerido: 3000USDT-10000USDT',
                    illustrate2: 'Tasa de interés diaria: 0,70%-0,75%',
                    illustrate3: 'Tasa de interés mensual: 21%-22,5%',
                    illustrate4: 'Firma: 30 días',
                    introduce: 'Monto de asignación requerido: 3000USDT-10000USDT Tasa de interés diaria: 0,70%-0,75% Tasa de interés mensual: 21%-22,5% Contrato: después de firmar con éxito el día 30, se requiere una asignación diaria para obtener ingresos de 3000USDT y el ingreso de retorno. el día 30 será de aproximadamente 630 USDT o si invierte más de 10 000 USDT, obtendrá retornos en 30 días. El monto de la inversión es de aproximadamente 2100 USDT o más. Puede elegir cualquier número entre 3000 y 10 000.'
                }


            ],
            [{
                title: "Versión profesional AI",
                illustrate1: 'Monto de asignación requerido: 10,000USDT-30,000USDT',
                illustrate2: 'Tasa de interés diaria: 0,80%-0,85%',
                illustrate3: 'Tasa de interés mensual: 24%-25,5%',
                illustrate4: 'Firma: 30 días',
                introduce: 'Después de firmar con éxito el contrato, debe asignar fondos todos los días para obtener ingresos. Invierta 10,000 USDT. El retorno el día 30 será de aproximadamente 2,400 USDT o más. Invierta 30,000 USDT, y el retorno el día 30 será de aproximadamente 7,200 USDT. o más. El monto de la inversión se puede elegir entre 10.000 y 30.000.'
            }
            ],
            [{
                title: "Edición empresarial AI",
                illustrate1: 'Monto de asignación requerido: 30 000 USDT-50 000 USDT',
                illustrate2: 'Tasa de interés diaria: 0,90%-0,95%',
                illustrate3: 'Tasa de interés mensual: 27%-28,5%',
                illustrate4: 'Firma: 30 días',
                introduce: 'Invierta 30.000 USDT y obtenga un rendimiento de aproximadamente 8.100 USDT o más en 30 días. Invierta 50.000 USDT y obtenga un rendimiento de aproximadamente 13.500 USDT o más en 30 días. El monto de la inversión se puede elegir entre 30.000 y 50.000.'
            }],
            [
                {
                    title: "Versión personalizada AI",
                    illustrate1: 'Monto de asignación requerido: 50 000-200 000',
                    illustrate2: 'Tasa de interés diaria: 0,01-0,012',
                    illustrate3: 'Tasa de interés mensual: 30%-36%',
                    illustrate4: 'Firma: 30 días',
                    introduce: 'Después de firmar con éxito el contrato, debe asignar fondos todos los días para obtener ingresos. Invierta 50 000 USDT. El rendimiento el día 30 será de aproximadamente 15 000 USDT o más. más El monto de la inversión se puede elegir entre 50.000 y 200.000.'
                }
            ]
        ]
    },
    'ita': {
        listdata: [
            [
                {
                    title: "Versione base AI",
                    illustrate1: 'Importo di allocazione richiesto: US$100-US$300',
                    illustrate2: 'Tasso di interesse giornaliero: 0,44%-0,45%',
                    illustrate3: 'Tasso di interesse mensile: circa 13,2%-13,5%',
                    illustrate4: 'Firma: 15',
                    introduce: "[Versione base di AI] è aperta ai principianti quando la utilizzano per la prima volta. Possono sperimentare l'effetto del tasso di rendimento e la stabilità apportati dai prodotti dell'azienda. Dopo aver firmato con successo, è necessario stanziare fondi ogni giorno per ottenere entrate Investi 100 USDT e il reddito verrà restituito il 15. Circa 7 USDT o più. Investimento di 300 USDT, reddito di ritorno in 15 giorni pari a circa 21 USDT o più. L'importo dell'investimento può essere scelto tra 100 e 300 numeri."
                }
            ],
            [
                {
                    title: "Edizione iniziale AI",
                    illustrate1: 'Importo di allocazione richiesto: 500USDT-1000USDT',
                    illustrate2: 'Tasso di interesse giornaliero: 0,48%-0,50%',
                    illustrate3: 'Tasso di interesse mensile: 14,4%-15%',
                    illustrate4: 'Firma: 30 giorni',
                    introduce: "Dopo aver firmato con successo il contratto, devi stanziare fondi ogni giorno per ottenere il reddito. Investi 500 USDT. Il rendimento in 30 giorni sarà di circa 72 USDT o più l'importo può essere scelto tra 500 e 1000."
                }
            ],
            [
                {
                    title: "Edizione standard AI",
                    illustrate1: 'Importo di allocazione richiesto: 1000 USDT-3000 USDT',
                    illustrate2: 'Tasso di interesse giornaliero: 0,60%-0,65%',
                    illustrate3: 'Tasso di interesse mensile: 18%-19,5%',
                    illustrate4: 'Firma: 30 giorni',
                    introduce: "Dopo aver firmato con successo il contratto, devi stanziare fondi ogni giorno per ottenere il reddito. Investi 1.000 USDT. Il reddito di ritorno al 30° giorno è di circa 180 USDT o più più L'importo dell'investimento può essere scelto tra 1.000 e 3.000 numeri. Il rendimento giornaliero è di circa 144 USDT o più. L'importo dell'investimento può essere scelto tra 500 e 1000 numeri."
                }
            ],
            [
                {
                    title: "Versione premium AI",
                    illustrate1: 'Importo di allocazione richiesto: 3000 USDT-10000 USDT',
                    illustrate2: 'Tasso di interesse giornaliero: 0,70%-0,75%',
                    illustrate3: 'Tasso di interesse mensile: 21%-22,5%',
                    illustrate4: 'Firma: 30 giorni',
                    introduce: "Importo di allocazione richiesto: 3000 USDT-10000 USDT Tasso di interesse giornaliero: 0,70%-0,75% Tasso di interesse mensile: 21%-22,5% Contratto: dopo aver firmato con successo il 30, è necessaria un'allocazione giornaliera per ottenere un reddito e un reddito di ritorno il 30 sarà di circa 630 USDT oppure Se investi più di 10.000 USDT, otterrai rendimenti in 30 giorni. L'importo dell'investimento è di circa 2.100 USDT o più. Puoi scegliere qualsiasi numero compreso tra 3.000 e 10.000."
                }


            ],
            [{
                title: "Versione professionale AI",
                illustrate1: 'Importo di allocazione richiesto: 10.000 USDT-30.000 USDT',
                illustrate2: 'Tasso di interesse giornaliero: 0,80%-0,85%',
                illustrate3: 'Tasso di interesse mensile: 24%-25,5%',
                illustrate4: 'Firma: 30 giorni',
                introduce: "Dopo aver firmato con successo il contratto, devi stanziare fondi ogni giorno per ottenere il reddito. Investi 10.000 USDT. Il rendimento il 30 sarà di circa 2.400 USDT o più. o più L'importo dell'investimento può essere scelto tra 10.000 e 30.000."
            }
            ],
            [{
                title: "Edizione aziendale AI",
                illustrate1: 'Importo di allocazione richiesto: 30.000 USDT-50.000 USDT',
                illustrate2: 'Tasso di interesse giornaliero: 0,90%-0,95%',
                illustrate3: 'Tasso di interesse mensile: 27%-28,5%',
                illustrate4: 'Firma: 30 giorni',
                introduce: "Investi 30.000 USDT e ottieni un rendimento di circa 8.100 USDT o più in 30 giorni Investi 50.000 USDT e ottieni un rendimento di circa 13.500 USDT o più in 30 giorni L'importo dell'investimento può essere scelto tra 30.000 e 50.000."
            }],
            [
                {
                    title: "Versione personalizzata AI",
                    illustrate1: 'Importo della dotazione richiesta: 50.000-200.000',
                    illustrate2: 'Tasso di interesse giornaliero: 0,01-0,012',
                    illustrate3: 'Tasso di interesse mensile: 30%-36%',
                    illustrate4: 'Firma: 30 giorni',
                    introduce: "Dopo aver firmato con successo il contratto, devi stanziare fondi ogni giorno per ottenere il reddito. Investi 50.000 USDT. Il rendimento il 30 sarà di circa 15.000 USDT o più in più L'importo dell'investimento può essere scelto tra 50.000 e 200.000."
                }
            ]
        ]
    },
    'pt': {
        listdata: [
            [
                {
                    title: "Versão básica AI",
                    illustrate1: 'Valor de alocação necessário: US$ 100-US$ 300',
                    illustrate2: 'Taxa de juros diária: 0,44% -0,45%',
                    illustrate3: 'Taxa de juros mensal: cerca de 13,2% -13,5%',
                    illustrate4: 'Assinatura: dia 15',
                    introduce: '[Versão básica da IA] está aberta para iniciantes experimentarem ao usá-la pela primeira vez. Eles podem experimentar o efeito da taxa de retorno e a estabilidade trazida pelos produtos da empresa. Após a assinatura bem-sucedida, você precisa alocar fundos todos os dias para obter renda. .Invista 100USDT, e o rendimento será devolvido no dia 15. Cerca de 7USDT ou mais de investimento de 300USDT, o rendimento de retorno de 15 dias é de cerca de 21USDT ou mais.'
                }
            ],
            [
                {
                    title: "Versão júnior AI",
                    illustrate1: 'Valor de alocação necessário: 500USDT-1000USDT',
                    illustrate2: 'Taxa de juros diária: 0,48% -0,50%',
                    illustrate3: 'Taxa de juros mensal: 14,4% -15%',
                    illustrate4: 'Assinatura: 30 dias',
                    introduce: 'Depois de assinar o contrato com sucesso, você precisa alocar fundos todos os dias para obter a renda. O retorno em 30 dias será de cerca de 72USDT ou mais. o valor pode ser escolhido entre 500 e 1000.'
                }
            ],
            [
                {
                    title: "Edição Padrão AI",
                    illustrate1: 'Valor de alocação necessário: 1000USDT-3000USDT',
                    illustrate2: 'Taxa de juros diária: 0,60% -0,65%',
                    illustrate3: 'Taxa de juros mensal: 18% -19,5%',
                    illustrate4: 'Assinatura: 30 dias',
                    introduce: 'Depois de assinar o contrato com sucesso, você precisa alocar fundos todos os dias para obter a receita. Investir 1.000USDT. A receita de retorno no 30º dia é de cerca de 180USDT ou mais. mais. O valor do investimento pode ser escolhido entre 1.000 e 3.000 números 30 O rendimento de retorno diário é de cerca de 144USDT ou mais.'
                }
            ],
            [
                {
                    title: "Versão premium AI",
                    illustrate1: 'Valor de alocação necessário: 3.000USDT-10.000USDT',
                    illustrate2: 'Taxa de juros diária: 0,70% -0,75%',
                    illustrate3: 'Taxa de juros mensal: 21% -22,5%',
                    illustrate4: 'Assinatura: 30 dias',
                    introduce: 'Valor de alocação necessário: 3.000USDT-10.000USDT Taxa de juros diária: 0,70%-0,75% Taxa de juros mensal: 21%-22,5% Contrato: Após assinatura bem-sucedida no dia 30, a alocação diária é necessária para obter renda Investir 3.000USDT, e o rendimento de retorno no dia 30. o dia 30 será de aproximadamente 630USDT ou se você investir mais de 10.000USDT, obterá retornos em 30 dias. O valor do investimento é de cerca de 2.100USDT ou mais.'
                }


            ],
            [{
                title: "Versão profissional AI",
                illustrate1: 'Valor de alocação necessário: 10.000USDT-30.000USDT',
                illustrate2: 'Taxa de juros diária: 0,80% -0,85%',
                illustrate3: 'Taxa de juros mensal: 24% -25,5%',
                illustrate4: 'Assinatura: 30 dias',
                introduce: 'Depois de assinar o contrato com sucesso, você precisa alocar fundos todos os dias para obter a renda. Investir 10.000USDT. O retorno no dia 30 será de cerca de 2.400USDT ou mais. ou mais. O valor do investimento pode ser escolhido entre 10.000 e 30.000.'
            }
            ],
            [{
                title: "Edição Empresarial AI",
                illustrate1: 'Valor de alocação necessário: 30.000USDT-50.000USDT',
                illustrate2: 'Taxa de juros diária: 0,90% -0,95%',
                illustrate3: 'Taxa de juros mensal: 27% -28,5%',
                illustrate4: 'Assinatura: 30 dias',
                introduce: 'Invista 30.000USDT e obtenha um retorno de cerca de 8.100USDT ou mais em 30 dias. Invista 50.000USDT e obtenha um retorno de cerca de 13.500USDT ou mais em 30 dias.'
            }],
            [
                {
                    title: "Versão personalizada AI",
                    illustrate1: 'Valor de alocação necessário: 50.000-200.000',
                    illustrate2: 'Taxa de juros diária: 0,01-0,012',
                    illustrate3: 'Taxa de juros mensal: 30% -36%',
                    illustrate4: 'Assinatura: 30 dias',
                    introduce: 'Depois de assinar o contrato com sucesso, você precisará alocar fundos todos os dias para obter a renda. Investir 50.000USDT. O retorno no dia 30 será de cerca de 15.000USDT ou mais. mais. O valor do investimento pode ser escolhido entre 50.000 e 200.000.'
                }
            ]
        ]
    },
    'nl': {
        listdata: [
            [
                {
                    title: "Basisversie AI",
                    illustrate1: 'Vereist toewijzingsbedrag: US$100-US$300',
                    illustrate2: 'Dagelijkse rente: 0,44%-0,45%',
                    illustrate3: 'Maandelijkse rente: ongeveer 13,2%-13,5%',
                    illustrate4: 'Ondertekening: 15e',
                    introduce: '[Basisversie van AI] staat open voor beginners om te ervaren wanneer ze het voor de eerste keer gebruiken. Ze kunnen het rendementseffect en de stabiliteit van de producten van het bedrijf ervaren. Na succesvolle ondertekening moet u elke dag geld toewijzen om inkomsten te krijgen Investeer 100 USDT en het inkomen wordt op de 15e terugbetaald. Een investering van ongeveer 7 USDT of meer van 300 USDT, een rendement over 15 dagen is ongeveer 21 USDT of meer.'
                }
            ],
            [
                {
                    title: "Starter editie AI",
                    illustrate1: 'Vereist toewijzingsbedrag: 500USDT-1000USDT',
                    illustrate2: 'Dagelijkse rente: 0,48%-0,50%',
                    illustrate3: 'Maandelijkse rente: 14,4%-15%',
                    illustrate4: 'Ondertekening: 30 dagen',
                    introduce: 'Nadat u het contract met succes heeft ondertekend, moet u elke dag geld toewijzen om de inkomsten te verkrijgen. Het rendement op 30 dagen zal ongeveer 72 USDT of meer zijn bedrag kan worden gekozen tussen 500 en 1000.'
                }
            ],
            [
                {
                    title: "Standaard editie AI",
                    illustrate1: 'Vereist toewijzingsbedrag: 1000USDT-3000USDT',
                    illustrate2: 'Dagelijkse rente: 0,60%-0,65%',
                    illustrate3: 'Maandelijkse rente: 18%-19,5%',
                    illustrate4: 'Ondertekening: 30 dagen',
                    introduce: 'Nadat u het contract met succes heeft ondertekend, moet u elke dag geld toewijzen om de inkomsten te ontvangen. Het rendement op de 30e dag bedraagt ​​ongeveer 180 USDT of meer meer. Het investeringsbedrag kan worden gekozen tussen 1.000 en 3.000 nummers. Het dagelijkse rendement bedraagt ​​ongeveer 144USDT of meer. Het investeringsbedrag kan worden gekozen tussen 500 en 1000 nummers.'
                }
            ],
            [
                {
                    title: "Premium-versie AI",
                    illustrate1: 'Vereist toewijzingsbedrag: 3000USDT-10000USDT',
                    illustrate2: 'Dagelijkse rente: 0,70%-0,75%',
                    illustrate3: 'Maandelijkse rente: 21%-22,5%',
                    illustrate4: 'Ondertekening: 30 dagen',
                    introduce: 'Vereist toewijzingsbedrag: 3000USDT-10000USDT Dagelijkse rente: 0,70%-0,75% Maandelijkse rente: 21%-22,5% Contract: Na succesvolle ondertekening op de 30e is een dagelijkse toewijzing vereist om inkomsten uit Invest 3000 te verkrijgen de 30e bedraagt ​​ongeveer 630 USDT. Als u meer dan 10.000 USDT investeert, krijgt u binnen 30 dagen rendement. Het investeringsbedrag bedraagt ​​ongeveer 2.100 USDT of meer. U kunt een getal tussen 3.000 en 10.000 kiezen.'
                }


            ],
            [{
                title: "Professionele versie AI",
                illustrate1: 'Vereist toewijzingsbedrag: 10.000USDT-30.000USDT',
                illustrate2: 'Dagelijkse rente: 0,80%-0,85%',
                illustrate3: 'Maandelijkse rente: 24%-25,5%',
                illustrate4: 'Ondertekening: 30 dagen',
                introduce: 'Nadat u het contract succesvol heeft ondertekend, moet u elke dag geld toewijzen om de inkomsten te verkrijgen. Het rendement op de 30e zal ongeveer 2.400 USDT of meer zijn. Investeer 30.000 USDT, en het rendement op de 30e zal ongeveer 7.200 USDT zijn. of meer Het investeringsbedrag kan worden gekozen tussen 10.000 en 30.000.'
            }
            ],
            [{
                title: "Enterprise-editie AI",
                illustrate1: 'Vereist toewijzingsbedrag: 30.000USDT-50.000USDT',
                illustrate2: 'Dagelijkse rente: 0,90%-0,95%',
                illustrate3: 'Maandelijkse rente: 27%-28,5%',
                illustrate4: 'Ondertekening: 30 dagen',
                introduce: 'Investeer 30.000 USDT en ontvang een rendement van ongeveer 8.100 USDT of meer in 30 dagen. Investeer 50.000 USDT en ontvang een rendement van ongeveer 13.500 USDT of meer in 30 dagen.'
            }],
            [
                {
                    title: "Aangepaste versie AI",
                    illustrate1: 'Vereist toewijzingsbedrag: 50.000-200.000',
                    illustrate2: 'Dagelijkse rente: 0,01-0,012',
                    illustrate3: 'Maandelijkse rente: 30%-36%',
                    illustrate4: 'Ondertekening: 30 dagen',
                    introduce: 'Nadat u het contract met succes heeft ondertekend, moet u elke dag geld toewijzen om de inkomsten te verkrijgen. Het rendement op de 30e zal ongeveer 15.000 USDT zijn of meer meer Het investeringsbedrag kan worden gekozen tussen 50.000 en 200.000.'
                }
            ]
        ]
    },
    'el': {
        listdata: [
            [
                {
                    title: "Βασική έκδοση AI",
                    illustrate1: 'Απαιτούμενο ποσό κατανομής: 100$-300$',
                    illustrate2: 'Ημερήσιο επιτόκιο: 0,44%-0,45%',
                    illustrate3: 'Μηνιαίο επιτόκιο: περίπου 13,2%-13,5%',
                    illustrate4: 'Υπογραφή: 15η',
                    introduce: 'Η [Βασική έκδοση του AI] είναι ανοιχτή για τους αρχάριους όταν το χρησιμοποιούν για πρώτη φορά. Μπορούν να βιώσουν το ποσοστό απόδοσης και τη σταθερότητα που προσφέρουν τα προϊόντα της εταιρείας Επενδύστε 100 USDT και το εισόδημα θα επιστραφεί στις 15. Περίπου 300 USDT ή περισσότερο, το εισόδημα επιστροφής 15 ημερών είναι περίπου 21 USDT ή περισσότερο'
                }
            ],
            [
                {
                    title: "Junior έκδοση AI",
                    illustrate1: 'Απαιτούμενο ποσό κατανομής: 500 USDT-1000 USDT',
                    illustrate2: 'Ημερήσιο επιτόκιο: 0,48%-0,50%',
                    illustrate3: 'Μηνιαίο επιτόκιο: 14,4%-15%',
                    illustrate4: 'Υπογραφή: 30 ημέρες',
                    introduce: 'Μετά την επιτυχή υπογραφή της σύμβασης, πρέπει να διαθέτετε κεφάλαια κάθε μέρα για να λάβετε το εισόδημα σε 30 USDT ή περισσότερο Το ποσό μπορεί να επιλεγεί μεταξύ 500 και 1000.'
                }
            ],
            [
                {
                    title: "Τυπική Έκδοση AI",
                    illustrate1: 'Απαιτούμενο ποσό κατανομής: 1000 USDT-3000 USDT',
                    illustrate2: 'Ημερήσιο επιτόκιο: 0,60%-0,65%',
                    illustrate3: 'Μηνιαίο επιτόκιο: 18%-19,5%',
                    illustrate4: 'Υπογραφή: 30 ημέρες',
                    introduce: 'Μετά την επιτυχή υπογραφή της σύμβασης, πρέπει να διαθέτετε χρήματα κάθε μέρα για να λάβετε το εισόδημα 1.000 USDT περισσότερα Το ποσό της επένδυσης μπορεί να επιλεγεί μεταξύ 1.000 και 3.000 αριθμώ'
                }
            ],
            [
                {
                    title: "Έκδοση Premium AI",
                    illustrate1: 'Απαιτούμενο ποσό κατανομής: 3000USDT-10000USDT',
                    illustrate2: 'Ημερήσιο επιτόκιο: 0,70%-0,75%',
                    illustrate3: 'Μηνιαίο επιτόκιο: 21%-22,5%',
                    illustrate4: 'Υπογραφή: 30 ημέρες',
                    introduce: 'Απαιτούμενο ποσό κατανομής: 3000USDT-10000USDT Ημερήσιο επιτόκιο: 0,70%-0,75% Μηνιαίο επιτόκιο: 21%-22,5% Σύμβαση: Μετά την επιτυχή υπογραφή στις 30, απαιτείται ημερήσια κατανομή για την απόκτηση εισοδήματος Επένδυση 3000 USDT και το εισόδημα επιστροφής Το 30ό ποσό θα είναι περίπου 630 USDT ή εάν επενδύσετε περισσότερα από 10.000 USDT, θα λάβετε επιστροφές σε 30 ημέρες. Το ποσό της επένδυσης είναι περίπου 2.100 USDT ή περισσότερο.'
                }


            ],
            [{
                title: "Επαγγελματική έκδοση AI",
                illustrate1: 'Απαιτούμενο ποσό κατανομής: 10.000 USDT-30.000 USDT',
                illustrate2: 'Ημερήσιο επιτόκιο: 0,80%-0,85%',
                illustrate3: 'Μηνιαίο επιτόκιο: 24%-25,5%',
                illustrate4: 'Υπογραφή: 30 ημέρες',
                introduce: 'Μετά την επιτυχή υπογραφή της σύμβασης, πρέπει να διαθέτετε χρήματα κάθε μέρα για να λάβετε το εισόδημα 10.000 USDT. ή περισσότερο Το ποσό της επένδυσης μπορεί να επιλεγεί μεταξύ 10.000 και 30.000.'
            }
            ],
            [{
                title: "Enterprise Edition AI",
                illustrate1: 'Απαιτούμενο ποσό κατανομής: 30.000 USDT-50.000 USDT',
                illustrate2: 'Ημερήσιο επιτόκιο: 0,90%-0,95%',
                illustrate3: 'Μηνιαίο επιτόκιο: 27%-28,5%',
                illustrate4: 'Υπογραφή: 30 ημέρες',
                introduce: 'Επενδύστε 30.000 USDT και λάβετε απόδοση περίπου 8.100 USDT σε 30 ημέρες και λάβετε απόδοση περίπου 13.500 USDT ή περισσότερο σε 30 ημέρες.'
            }],
            [
                {
                    title: "Προσαρμοσμένη έκδοση AI",
                    illustrate1: 'Απαιτούμενο ποσό διάθεσης: 50.000-200.000',
                    illustrate2: 'Ημερήσιο επιτόκιο: 0,01-0,012',
                    illustrate3: 'Μηνιαίο επιτόκιο: 30%-36%',
                    illustrate4: 'Υπογραφή: 30 ημέρες',
                    introduce: 'Μετά την επιτυχή υπογραφή της σύμβασης, πρέπει να διαθέτετε χρήματα κάθε μέρα για να λάβετε το εισόδημα στις 30.000 USDT ή περισσότερο περισσότερα Το ποσό της επένδυσης μπορεί να επιλεγεί μεταξύ 50.000 και 200.000.'
                }
            ]
        ]
    },
    'pl': {
        listdata: [
            [
                {
                    title: "Wersja podstawowa AI",
                    illustrate1: 'Wymagana kwota alokacji: 100-300 USDT',
                    illustrate2: 'Dzienna stopa procentowa: 0,44% -0,45%',
                    illustrate3: 'Miesięczne oprocentowanie: ok. 13,2%-13,5%',
                    illustrate4: 'Podpisanie: 15',
                    introduce: '[Podstawowa wersja AI] jest otwarta dla nowicjuszy, którzy korzystają z niej po raz pierwszy, mogą doświadczyć stopy zwrotu i stabilności, jaką zapewniają produkty firmy. Po pomyślnym podpisaniu trzeba codziennie przeznaczyć środki, aby uzyskać dochód Zainwestuj 100 USDT, a dochód zostanie zwrócony 15-go. Około 7 USDT lub więcej przy inwestycji o wartości 300 USDT, 15-dniowy dochód z inwestycji wynosi około 21 USDT lub więcej. Kwotę inwestycji można wybrać z zakresu od 100 do 300.'
                }
            ],
            [
                {
                    title: "Wersja startowa AI",
                    illustrate1: 'Wymagana kwota alokacji: 500USDT-1000USDT',
                    illustrate2: 'Dzienna stopa procentowa: 0,48% -0,50%',
                    illustrate3: 'Miesięczne oprocentowanie: 14,4%-15%',
                    illustrate4: 'Podpisanie: 30 dni',
                    introduce: 'Po pomyślnym podpisaniu umowy musisz codziennie przeznaczyć środki, aby uzyskać dochód w wysokości 500 USDT. Zwrot w ciągu 30 dni wyniesie około 72 USDT lub więcej. Zwrot w przypadku inwestycji w ciągu 30 dni wyniesie około 144 USDT lub więcej kwotę można wybrać z zakresu od 500 do 1000.'
                }
            ],
            [
                {
                    title: "Wersja standardowa AI",
                    illustrate1: 'Wymagana kwota alokacji: 1000USDT-3000USDT',
                    illustrate2: 'Dzienna stopa procentowa: 0,60% -0,65%',
                    illustrate3: 'Miesięczne oprocentowanie: 18%-19,5%',
                    illustrate4: 'Podpisanie: 30 dni',
                    introduce: 'Po pomyślnym podpisaniu umowy musisz codziennie przeznaczyć środki, aby uzyskać dochód w wysokości 1000 USDT. Dochód z zwrotu w 30. dniu wynosi około 180 USDT lub więcej. Zainwestuj 3000 USDT w przypadku zwrotu w 30. dniu więcej. Kwotę inwestycji można wybrać od 1000 do 3000 liczb. 30 Dzienny dochód ze zwrotu wynosi około 144 USDT lub więcej. Kwotę inwestycji można wybrać od 500 do 1000 liczb.'
                }
            ],
            [
                {
                    title: "Wersja premium AI",
                    illustrate1: 'Wymagana kwota alokacji: 3000USDT-10000USDT',
                    illustrate2: 'Dzienna stopa procentowa: 0,70% -0,75%',
                    illustrate3: 'Miesięczne oprocentowanie: 21%-22,5%',
                    illustrate4: 'Podpisanie: 30 dni',
                    introduce: 'Wymagana kwota alokacji: 3000USDT-10000USDT Dzienna stopa procentowa: 0,70%-0,75% Miesięczna stopa procentowa: 21%-22,5% Kontrakt: Po pomyślnym podpisaniu 30-go dnia wymagana jest dzienna alokacja, aby uzyskać dochód 3000USDT i zwrot dochodu 30-tego wyniesie około 630 USDT lub Jeśli zainwestujesz więcej niż 10 000 USDT, otrzymasz zwrot w ciągu 30 dni. Kwota inwestycji wynosi około 2100 USDT lub więcej. Możesz wybrać dowolną liczbę od 3 000 do 10 000.'
                }


            ],
            [{
                title: "Wersja profesjonalna AI",
                illustrate1: 'Wymagana kwota alokacji: 10 000 USDT-30 000 USDT',
                illustrate2: 'Dzienna stopa procentowa: 0,80% -0,85%',
                illustrate3: 'Miesięczne oprocentowanie: 24%-25,5%',
                illustrate4: 'Podpisanie: 30 dni',
                introduce: 'Po pomyślnym podpisaniu umowy musisz codziennie przeznaczyć środki, aby uzyskać dochód 30 000 USDT. Zainwestuj 30 000 USDT, a zwrot 30-go wyniesie około 7 200 USDT. lub więcej Kwota inwestycji może wynosić od 10 000 do 30 000.'
            }
            ],
            [{
                title: "Wersja korporacyjna",
                illustrate1: 'Wymagana kwota alokacji: 30 000 USDT-50 000 USDT',
                illustrate2: 'Dzienna stopa procentowa: 0,90% -0,95%',
                illustrate3: 'Miesięczne oprocentowanie: 27%-28,5%',
                illustrate4: 'Podpisanie: 30 dni',
                introduce: 'Zainwestuj 30 000 USDT i uzyskaj zwrot w wysokości około 8 100 USDT lub więcej w ciągu 30 dni Zainwestuj 50 000 USDT i uzyskaj zwrot w wysokości około 13 500 USDT lub więcej w ciągu 30 dni. Kwota inwestycji może wynosić od 30 000 do 50 000.'
            }],
            [
                {
                    title: "Wersja dostosowana AI",
                    illustrate1: 'Wymagana kwota alokacji: 50 000-200 000',
                    illustrate2: 'Dzienna stopa procentowa: 0,01-0,012',
                    illustrate3: 'Miesięczne oprocentowanie: 30% -36%',
                    illustrate4: 'Podpisanie: 30 dni',
                    introduce: 'Po pomyślnym podpisaniu umowy musisz codziennie przeznaczyć środki, aby uzyskać dochód 30 000 USDT lub więcej. Zainwestuj 200 000 USDT. Zwrot 30-tego wyniesie około 60 000 USDT więcej Kwota inwestycji może wynosić od 50 000 do 200 000.'
                }
            ]
        ]
    },
}

export default packListData