export default {
    navList: {
        nav1: 'Accueil',
        nav2: 'Avantages',
        nav3: 'Portefeuille',
        nav4: 'Vidéos AI',
        nav5: 'Contact',
        login: 'Connexion',
        register: 'Inscription'
    },
    Language: "Langue",
    down: 'Télécharger',
    Menu: 'Menu',
    bannerTitle: '[STAR AI] Trading Quantitatif',
    bannerSubtitle: 'Rendement Stable',
    bannerListTxt1: 'Rééquilibrage',
    bannerListTxt2: 'Gestion de Portefeuille',
    bannerListTxt3: 'Collecte de Données',
    bannerListTxt4: 'Routage Intelligent',
    bannerDownBtn: 'Télécharger Android',
    bannerSubscribeBtn: 'S’abonner Maintenant',
    ProductAdvantages: 'Avantages vs Humain',
    advantagesList: {
        title1: "La différence entre les avantages du produit et la main-d'œuvre",
        describe1: "Les avantages de conception des modèles de stratégie quantitative d'IA. Chaque modèle quantitatif d'IA est composé de plus de centaines de facteurs qui peuvent affecter la hausse et la baisse des actions. Il dispose de capacités d'apprentissage en profondeur indépendantes et peut suivre le flux principal de fonds. Il est difficile pour l’analyse et la recherche manuelles de répondre à de telles exigences.",
        title2: 'Avantages de la fonction de test de retour de données historiques',
        describe2: "Pour concevoir un modèle quantitatif d'IA avec un taux de rendement qui dépasse de loin le taux de rendement du marché, vous pouvez obtenir le taux de rendement annualisé historique du modèle en effectuant un back-test des données du cycle historique. Parmi eux, le modèle le plus idéal peut être sélectionné. C'est tout simplement impossible à faire manuellement, quel que soit le temps que cela prend. Par conséquent, les opérations manuelles ne sont souvent ni rigoureuses ni scientifiques, et reposent uniquement sur les sentiments et l’expérience personnels pour sélectionner les cibles à opérer.",
        title3: "Avantages de l'analyse automatique de plus de 400 000 actions sur le marché mondial",
        describe3: "Le robot IA analyse automatiquement plus de 400 000 actions sur l'ensemble du marché tout au long de la journée selon les conditions et autres paramètres définis par les techniciens du modèle quantitatif, et termine l'opération immédiatement lorsqu'il constate que les conditions cibles définies sont remplies. Cela ne prend que quelques secondes. L’analyse manuelle prendrait des années, voire des décennies, et ne permettrait pas d’accomplir la même quantité de travail.",
        title4: "Ce n'est qu'en comprenant l'histoire que nous pourrons juger l'avenir avec plus de précision",
        describe4: "Le taux de rendement du backtest des données historiques du modèle quantitatif peut être utilisé comme meilleure référence pour juger des taux de rendement futurs. Les ingénieurs techniques n'ont qu'à saisir directement les paramètres relatifs aux actions achetées ce jour-là, et ils peuvent backtester le taux de rendement quotidien. du stock au point d'achat. Pour répondre à ces exigences, une opération manuelle est tout simplement impossible.",
        title5: 'Établir un modèle de profit stable et à long terme',
        describe5: "Pour obtenir des bénéfices stables à long terme et d'énormes intérêts composés, les ingénieurs techniques peuvent personnaliser et ajouter leurs propres paramètres d'indicateurs sélectionnés, vérifier le taux de rendement via des backtests, sélectionner la stratégie ou la combinaison la plus idéale, puis effectuer des transactions entièrement automatiques avec des robots IA. Ce type de travail ne peut pas être effectué manuellement.",
        title6: "Capacité d’exécution opérationnelle",
        describe6: "L'avantage du trading quantitatif entièrement automatique de STAR AI est qu'il utilise des outils rationnels pour effectuer des transactions selon les paramètres du modèle. Le robot ne subit aucun changement émotionnel et peut surmonter la nature humaine, l'avidité et la peur humaines ainsi que de nombreuses autres lacunes et faiblesses du processus opérationnel. Les opérations manuelles sont souvent affectées par la hausse et la baisse du marché et par divers autres facteurs, et ne peuvent pas être exploitées pendant longtemps selon le meilleur modèle de profit.",
        title7: 'Libérez complètement la productivité',
        describe7: "Le robot quantitatif STAR AI peut être configuré une fois et effectuer automatiquement des opérations selon les paramètres pendant une longue période. Il n'a besoin d'effectuer le processus d'allocation de capital qu'une fois par jour, ce qui peut libérer complètement les utilisateurs du travail ardu de la négociation d'actions. Cependant, l’analyse et la recherche manuelles, le suivi du marché et les transactions manuelles confiées nécessitent beaucoup de temps et d’énergie de la part des investisseurs.",
    },
    investmentPackage: "Choisissez votre Portefeuille",
    versionTab1: 'Basique',
    versionTab2: 'Débutant',
    versionTab3: 'Standard',
    versionTab4: 'Avancé',
    versionTab5: 'Pro',
    versionTab6: 'Entreprise',
    versionTab7: 'Personnalisé',
    BusinessDocking: 'Partenariat',
    email: "E-mail",
    officialEmail: 'E-mail Officiel',
    privacyPolicy: 'Politique de Confidentialité',
    TermOfService: "Conditions d'Utilisation",
    Copyright: 'Droits d’Auteur'
}